<script>
import Layout from "../../layouts/main";
import VideosLayout from "../../layouts/videosLayout.vue";
import errorHandler from "@/helpers/errorHandler";
import { validationMixin } from "vuelidate";
import { required, integer } from 'vuelidate/lib/validators'

/**
 * Starter component
 */
export default {
  page: {
    title: "Lecture Minutes Adder",
  },
  head() {
    return {
      title: `Lecture-Minutes-Adder`,
    };
  },
  mixins: [validationMixin],
  validations:{
    form:{
      stud_id: {
        required,
      },
    }
  },
  components: {
    Layout,
    VideosLayout
  },
  data(){
    return {
      form:{
        stud_id: null,
        stage_id: null,
        lecture_id: null,
      },
      submit: false,
      stageError: null,
      lectureError: null,
    }
  },
  async created() {
    await this.$store.dispatch('option/getYearOption').then(()=>{}).catch( err =>{
      errorHandler.methods.errorMessage(err);
    });
  },
  computed: {
    stages(){
      return this.$store.getters['option/years'];
    },
    lectures(){
      return this.$store.getters['option/chapters'];
    },
  },
  methods: {
    validate(){
      if(this.form.stage_id){
        this.stageError = true;
      }else{
        this.stageError = false;
      }
      if(this.form.lecture_id){
        this.lectureError = true;
      }else{
        this.lectureError = false;
      }
      return this.stageError && this.lectureError;
    },
    async handleSubmit() {
      this.submit = true;
      await this.$v.$touch();
      if(this.validate() &&!this.$v.$anyError ){
        // requests for the create
        this.$store.dispatch("video/addLectureMinutes",this.form).then(
           ()=> {
            errorHandler.methods.successful(`Minutes Added to student ID: ${this.form.stud_id}`)
          }
        ).catch((err)=>{
          errorHandler.methods.errorMessage(err);
        });
      }
    },
    async clearSelectError5(){
      this.stageError = null;
      this.form.lecture_id = '';
      await this.$store.dispatch('option/clearChaptersOption');
      await this.$store.dispatch('option/getYearChapterOption', this.form.stage_id).then(()=>{}).catch( err =>{
      errorHandler.methods.errorMessage(err);
    });
    },
    clearSelectError6(){
      this.lectureError = null;
    },
  },
};
</script>

<template>
  <Layout>
    <VideosLayout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h2 class="card-title">
                Lecture Minutes Adder: 
            </h2>
            <form action="#" @submit.prevent="handleSubmit">
              <div class="row">
                  <div class="col-md-6" >
                    <b-form-group label="Stage:" label-for="input-C" >
                      <b-form-select   v-model="form.stage_id" :options="this.stages" :state="stageError" @change="clearSelectError5"></b-form-select>
                      <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="col-md-6" v-if="form.stage_id">
                    <b-form-group label="Lecture:" label-for="input-C" >
                      <b-form-select  v-model="form.lecture_id" :options="this.lectures" :state="lectureError" @change="clearSelectError6"></b-form-select>
                      <b-form-invalid-feedback id="input-2-live-feedback">This is a required field.</b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                  <b-form-group
                      id="input-group-2"
                      label="Student ID:"
                      label-for="input-D"
                  >
                      <input  v-model="form.stud_id" type="text" name="name" class="form-control" :class="{ 'is-invalid': submit && $v.form.stud_id.$error }" placeholder="Enter Student ID" />
                      <div v-if="submit && $v.form.stud_id.$error" class="invalid-feedback">
                          <span v-if="!$v.form.stud_id.required">This field is required.</span>
                      </div>
                  </b-form-group>
                  </div>
              </div>
            </form>
          </div>
          <hr />
          <div class="text-center">
              <button style="width: 15rem;" class="btn btn-success btn-rounded mb-2" type="submit" @click="handleSubmit">Submit</button>
          </div>
        </div>
      </div>
    </div>
    </VideosLayout>
  </Layout>
</template>
